import axiosIns from '@/libs/axios'

export default {
  allClass: () => axiosIns.get('accounting/std-fee-config/class'),
  classSession: classId => axiosIns.get(`accounting/std-fee-config/class-session/${classId}`),
  allCampus: () => axiosIns.get('accounting/std-fee-config/campus'),
  allFees: () => axiosIns.get('accounting/std-fee-config/fee-name'),
  getGroups: () => axiosIns.get('accounting/std-fee-config/group-list'),
  paymentCycles: (sessionId, classId, campusIds) => axiosIns.get(`accounting/std-fee-config/payment-cycle/${sessionId}/${classId}/${campusIds}`),
  getAllFeeConfig: (classId, sessionId) => axiosIns.get(`accounting/std-fee-config/read/${classId}/${sessionId}`),
  hasCoaConfig: coaId => axiosIns.get(`accounting/std-fee-config/has-coa-config/${coaId}`),
  saveStdFeeConfig: params => axiosIns.post('accounting/std-fee-config', params),
  updateStdFeeConfig: (updateId, params) => axiosIns.put(`accounting/std-fee-config/${updateId}`, params),
  editStdFeeConfig: editId => axiosIns.get(`accounting/std-fee-config/${editId}`),
  deleteStdFeeConfig: deleteId => axiosIns.delete(`accounting/std-fee-config/${deleteId}`),
}
