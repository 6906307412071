/* eslint-disable no-param-reassign */
<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Student Fee Config
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >

        Activity log
      </b-link>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <div>
        <b-form-group>
          <b-button
            variant="outline-primary"
            :disabled="!stdFeeConfigClassFilter"
            @click.prevent="openFeeConfigModal"
          >
            Add Student Fee
          </b-button>
        </b-form-group>
      </div>

      <!-- Head Type -->
      <div class="d-flex col-5 mr-5 pr-0">
        <b-form-group
          label-for="head-type-filter"
          class="pr-0 col-7"
        >
          <v-select
            v-model="stdFeeConfigClassFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Class"
            :options="stdFeeConfigClassOptions"
            :reduce="(val) => val.id"
            @input="getClass"
          />
        </b-form-group>
        <b-form-group
          label-for="head-type-filter"
          class="pr-0 col-7"
        >
          <v-select
            v-model="stdFeeConfigSessionFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Session"
            :options="stdFeeConfigSessionOptions"
            :reduce="(val) => val.id"
            @input="getSession"
          />
        </b-form-group>
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :line-numbers="true"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: affectOn -->
        <span v-if="props.column.field === 'affectOn'">
          <span class="d-flex">
            <p v-if="+props.row.affectOn === 1">Monthly</p>
            <p v-else>The Rest</p>
          </span>
        </span>
        <!-- Column: Update -->
        <!-- <span v-else-if="props.column.affectOn === 1">
          <span class="d-flex">
            'Monthly (Selected)
          </span>
        </span> -->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '20', '30', '40', '50']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Student Fee Config Modal -->
    <b-modal
      id="std-fee-config-modal"
      title="Student Fee Config"
      ok-title="Save"
      cancel-title="Close"
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      no-close-on-backdrop
      @ok.prevent="saveStdFeeConfig"
    >
      <b-form @submit.prevent>
        <div>
          <span
            v-if="isTitle"
            class="text-info"
          >Transport amount take from transport module</span>
          <!-- Fee Name -->
          <b-row class="mt-1">
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Fee Name"
                label-for="fee-name-filter"
                label-cols-md="3"
                invalid-feedback="Fee Name is required"
                :state="feeNameState"
              >
                <v-select
                  v-model="stdFeeConfigData.feeName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="gl_name"
                  :options="stdFeeNameOptions"
                  placeholder="Select Fee Name"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="isAmount"
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Amount"
                label-for="std_fee_config_amount"
                label-cols-md="3"
                invalid-feedback="Amount is required"
                :state="amountState"
              >
                <b-form-input
                  id="std_fee_config_amount"
                  v-model="stdFeeConfigData.amount"
                  type="number"
                  placeholder="Amount"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Amount -->

          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Priority"
                label-for="std_fee_config_amount"
                label-cols-md="3"
                invalid-feedback="Priority is required"
                :state="priorityState"
              >
                <b-form-input
                  id="std_fee_config_amount"
                  v-model="stdFeeConfigData.priority"
                  type="number"
                  placeholder="Priority"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="hasGroup === 1">
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Group Name"
                label-for="fee-name-filter"
                label-cols-md="3"
                invalid-feedback="Group Name is required"
                :state="groupNameState"
              >
                <v-select
                  v-model="stdFeeConfigData.groupName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="groupNameOptions"
                  placeholder="Select Group Name"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Vat Applicable -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Vat Applicable"
                label-cols-md="3"
              >
                <b-form-checkbox
                  id="std_fee_config_vat"
                  v-model="stdFeeConfigData.vatApplicable"
                  value="1"
                  unchecked-value="0"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Has type -->
          <b-row v-if="hasType">
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Student Type"
                label-cols-md="3"
                invalid-feedback="Student Type is required"
                :state="studentType"
              >
                <b-form-radio-group
                  id="std_type"
                  v-model="stdFeeConfigData.typeId"
                  :options="stdTypeOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Applicable On -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Applicable On"
                label-cols-md="3"
                invalid-feedback="Applicable On is required"
                :state="applicationOnState"
              >
                <b-form-radio-group
                  id="std_fee_config_applicable_on"
                  v-model="stdFeeConfigData.applicableOn"
                  :options="stdFeeConfigApplicableOnOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
              label-cols-md="3"
            >
              <b-form-group
                label="Affect On"
                label-cols-md="3"
              >
                <b-form-radio-group
                  id="std_fee_config_affectOn"
                  v-model="stdFeeConfigData.affectOn"
                  :options="stdFeeConfigAffectOnOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Affect On -->
          <template
            v-if="stdFeeConfigData.affectOn && stdFeeConfigData.affectOn == 2"
          >
            <!-- Branch -->
            <b-row>
              <b-col
                cols="11"
                md="11"
                class="mb-md-0 mb-2"
                label-cols-md="3"
              >
                <b-form-group
                  label="Campus Name"
                  label-cols-md="3"
                  invalid-feedback="Campus Name is required"
                  :state="campusState"
                >
                  <v-select
                    v-model="stdFeeConfigData.campusIds"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    multiple
                    :options="stdFeeConfigCampusOptions"
                    placeholder="Select Campus Name"
                    :reduce="(val) => val.id"
                    @input="getPaymentCycle"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Payment Cycle -->
            <b-row>
              <b-col
                cols="11"
                md="11"
                class="mb-md-0 mb-2"
                label-cols-md="3"
              >
                <b-form-group
                  label="Payment Cycle Name"
                  label-cols-md="3"
                  invalid-feedback="Payment Cycle is required"
                  :state="paymentCycleState"
                >
                  <v-select
                    v-model="stdFeeConfigData.paymentCycleIds"
                    v-b-tooltip.hover.bottom="'Need to choose campus first'"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="cycle_name"
                    multiple
                    :options="stdFeeConfigPaymentCycleOptions"
                    placeholder="Select Payment Cycle Name"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </template>
        </div>
      </b-form>
    </b-modal>

    <!-- Audit Activity Modal -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BPagination,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormCheckbox,
  BFormRadioGroup,
  VBModal,
  BButton,
  BRow,
  BCol,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import StdFeeConfigService from '@/@service/api/accounting/StdFeeConfig.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BFormRadioGroup,
    vSelect,
    BButton,
    BRow,
    BCol,
    RefreshCwIcon,
    ActivityModal,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      stdFeeConfigData: {
        feeName: '',
        amount: '',
        priority: '',
        groupName: '',
        vatApplicable: 0,
        applicableOn: 1,
        affectOn: 1,
        typeId: '',
        campusIds: [],
        paymentCycleIds: [],
      },

      hasGroup: '',
      hasType: '',

      stdFeeConfigAffectOnOptions: [
        { text: 'Monthly', value: 1 },
        { text: 'Specific', value: 2 },
      ],

      stdFeeNameOptions: [],
      groupNameOptions: [],
      stdFeeConfigCampusOptions: [],
      stdFeeConfigPaymentCycleOptions: [],

      stdFeeConfigApplicableOnOptions: [
        { text: 'All Students', value: 1 },
        { text: 'New Students', value: 2 },
        { text: 'Existing Students', value: 3 },
      ],
      stdTypeOptions: [
        { text: 'Banker', value: 1 },
        { text: 'Non Banker', value: 2 },
      ],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Fee Name',
          field: 'coa',
        },
        {
          label: 'Amount',
          field: 'amount',
          sortable: false,
        },
        {
          label: 'Priority',
          field: 'priority',
        },
        {
          label: 'Affect On',
          field: 'affect_name',
        },
        {
          label: 'Group',
          field: 'group.name',
          // hidden: this.hasGroup !== 1,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],

      searchTerm: '',

      isUpdate: false,
      updateId: '',

      feeNameState: null,
      groupNameState: null,
      amountState: null,
      priorityState: null,
      affectionState: null,
      campusState: null,
      paymentCycleState: null,
      applicationOnState: null,
      studentType: null,

      stdFeeConfigClassFilter: null,
      stdFeeConfigSessionFilter: null,
      stdFeeConfigCampusFilter: [],

      stdFeeConfigClassOptions: [],
      stdFeeConfigSessionOptions: [],
      stdFeeCategoryOptions: [],
      isAmount: false,
      isTitle: false,
      // Activity Log Data
      tag: 'std-fee-config',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    'stdFeeConfigData.feeName': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onCheckConfig()
      }
    },
    stdFeeConfigClassFilter() {
      this.hasGroup = this.stdFeeConfigClassOptions.find(x => x.id === this.stdFeeConfigClassFilter).has_group
      this.hasType = this.stdFeeConfigClassOptions.find(x => x.id === this.stdFeeConfigClassFilter).has_type
    },

  },
  created() {
    this.getStdFeeConfigClass()
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (bvEvent.type === 'show' && modalId === 'std-fee-config-modal') { this.resetForm() }
    })
    this.getCampusNames()
    this.getFeeName()
  },
  methods: {
    async getStdFeeConfigClass() {
      this.stdFeeConfigClassFilter = null
      await StdFeeConfigService.allClass().then(res => {
        if (res.data.status) {
          this.stdFeeConfigClassOptions = res.data.data
          this.stdFeeConfigClassFilter = res.data.data[0].id
        }
      })

      if (this.stdFeeConfigClassFilter) {
        await this.getStdFeeConfigSession()
      }
    },
    async getStdFeeConfigSession() {
      if (!this.stdFeeConfigClassFilter) {
        return
      }
      await StdFeeConfigService.classSession(this.stdFeeConfigClassFilter).then(res => {
        if (res.data.status) {
          this.stdFeeConfigSessionOptions = res.data.data
          this.stdFeeConfigSessionFilter = this.stdFeeConfigSessionOptions.find(
            x => x.is_current === 1,
          ).id
          if (!this.stdFeeConfigSessionFilter) {
            FemsToastrService.error('Current Session Info not found')
          }
        }
      })
      if (this.stdFeeConfigSessionFilter) await this.getGridData()
    },
    getClass(val) {
      this.stdFeeConfigClassFilter = val
      console.log('🚀 ~ file: StdFeeConfig.vue ~ line 668 ~ getClass ~ this.stdFeeConfigClassFilter', this.stdFeeConfigClassFilter)
      if (!this.stdFeeConfigClassFilter) {
        this.stdFeeConfigSessionFilter = null
        this.stdFeeConfigSessionOptions = []
      }
      this.getStdFeeConfigSession()
      this.getGridData()
    },
    // get session value when select
    getSession(value) {
      this.stdFeeConfigSessionFilter = value
      this.getGridData()
    },
    // get campus name
    getCampusNames() {
      StdFeeConfigService.allCampus().then(res => {
        this.stdFeeConfigCampusOptions = res.data.data
      })
    },
    // get fee name
    getFeeName() {
      StdFeeConfigService.allFees().then(res => {
        this.stdFeeNameOptions = res.data.data
      })
    },
    getGroups() {
      StdFeeConfigService.getGroups().then(res => {
        this.groupNameOptions = res.data.data
      })
    },
    // get payment cycle when select campus ids
    getPaymentCycle(values) {
      if (!values) {
        return
      }
      this.stdFeeConfigCampusFilter = values
      this.stdFeeConfigPaymentCycleOptions = []
      StdFeeConfigService.paymentCycles(this.stdFeeConfigSessionFilter, this.stdFeeConfigClassFilter, JSON.stringify(this.stdFeeConfigData.campusIds)).then(res => {
        if (res.data.status) {
          this.stdFeeConfigPaymentCycleOptions = res.data.data
        } else {
          FemsToastrService.error(res?.data.message)
        }
      })
    },
    // Grid data
    getGridData() {
      if (!this.stdFeeConfigClassFilter || !this.stdFeeConfigSessionFilter) {
        this.rows = []
        return
      }
      StdFeeConfigService.getAllFeeConfig(this.stdFeeConfigClassFilter, this.stdFeeConfigSessionFilter).then(res => {
        if (res.data.status) {
          this.rows = res.data.data
          if (this.hasGroup) {
            this.rows.forEach(element => {
              // eslint-disable-next-line no-param-reassign
              element.group.name = element.group.name === 'N/A' ? 'All' : element.group.name
            })
          }
        } else {
          this.rows = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    refreshGrid() {
      this.getGridData()
    },
    onCheckConfig() {
      if (!this.stdFeeConfigData.feeName) {
        this.isAmount = true
        this.isTitle = false
        return
      }
      StdFeeConfigService.hasCoaConfig(this.stdFeeConfigData.feeName).then(res => {
        if (res.data.status && res.data.data) {
          this.isAmount = false
          this.isTitle = true
          this.stdFeeConfigData.amount = 0
          return
        }
        this.isAmount = true
        this.isTitle = false
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
          this.isAmount = true
          this.isTitle = false
        })
    },

    validationModal() {
      this.feeNameState = null
      this.groupNameState = null
      this.amountState = null
      this.priorityState = null
      this.campusState = null
      this.paymentCycleState = null
      this.applicationOnState = null
      this.studentType = null
      if (this.stdFeeConfigData.feeName === '' || this.stdFeeConfigData.feeName === null) {
        this.feeNameState = false
        return false
      }
      if (this.isAmount && (this.stdFeeConfigData.amount === '' || this.stdFeeConfigData.amount === null)) {
        this.amountState = false
        return false
      }

      if (this.stdFeeConfigData.priority === '' || this.stdFeeConfigData.priority === null) {
        this.priorityState = false
        return false
      }
      if (this.stdFeeConfigData.typeId === '' || this.stdFeeConfigData.typeId === null) {
        this.studentType = false
        return false
      }
      if (this.stdFeeConfigData.applicableOn === '' || this.stdFeeConfigData.applicableOn === null) {
        this.applicationOnState = false
        return false
      }

      if (this.stdFeeConfigData.affectOn === '' || this.stdFeeConfigData.affectOn === 2) {
        if (this.stdFeeConfigData.campusIds.length < 1) {
          this.campusState = false
          return false
        }
        if (this.stdFeeConfigData.paymentCycleIds.length < 1) {
          this.paymentCycleState = false
          return false
        }
      }

      if (this.hasGroup) {
        if (this.stdFeeConfigData.groupName === '') {
          this.groupNameState = false
          return false
        }
      }
      return true
    },

    openFeeConfigModal() {
      if (!this.stdFeeConfigClassFilter || !this.stdFeeConfigSessionFilter) {
        FemsToastrService.error('Class and Session must be selected')
        return
      }
      this.resetForm()
      if (this.hasGroup) {
        this.getGroups()
      }
      // this.hasGroup = this.stdFeeConfigClassOptions.find(x => x.id === this.stdFeeConfigClassFilter).has_group
      this.$bvModal.show('std-fee-config-modal')
    },

    saveStdFeeConfig() {
      let temp = {}
      /*  if (!this.validationModal()) {
        return
      } */
      if (this.stdFeeConfigData.affectOn && this.stdFeeConfigData.affectOn === 2) {
        temp = {
          coaId: this.stdFeeConfigData.feeName,
          amount: this.isAmount ? this.stdFeeConfigData.amount : 0,
          priority: this.stdFeeConfigData.priority,
          hasVat: this.stdFeeConfigData.vatApplicable,
          applicableOn: this.stdFeeConfigData.applicableOn,
          type_id: this.stdFeeConfigData.typeId,
          affectOn: 2,
          campusIds: JSON.stringify(this.stdFeeConfigData.campusIds),
          paymentCycleIds: JSON.stringify(
            this.stdFeeConfigData.paymentCycleIds,
          ),
          secAcaSessionId: this.stdFeeConfigSessionFilter,
          classId: this.stdFeeConfigClassFilter,
          groupOptionId: this.stdFeeConfigData.groupName,
        }
      } else {
        temp = {
          coaId: this.stdFeeConfigData.feeName,
          amount: this.isAmount ? this.stdFeeConfigData.amount : 0,
          priority: this.stdFeeConfigData.priority,
          hasVat: this.stdFeeConfigData.vatApplicable,
          applicableOn: this.stdFeeConfigData.applicableOn,
          type_id: this.stdFeeConfigData.typeId,
          affectOn: 1,
          secAcaSessionId: this.stdFeeConfigSessionFilter,
          classId: this.stdFeeConfigClassFilter,
          groupOptionId: this.stdFeeConfigData.groupName,
        }
      }
      const params = temp
      if (this.isUpdate) {
        StdFeeConfigService.updateStdFeeConfig(this.updateId, params).then(res => {
          if (res.data.status) {
            this.updateId = null
            this.isUpdate = false
            FemsToastrService.success(res?.data.message)
            this.$bvModal.hide('std-fee-config-modal')
            this.getGridData()
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
          .catch(err => {
            FemsToastrService.error(err?.message)
          })
      } else {
        StdFeeConfigService.saveStdFeeConfig(params).then(res => {
          if (res.data.status) {
            FemsToastrService.success(res?.data.message)
            this.$bvModal.hide('std-fee-config-modal')
            this.getGridData()
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
          .catch(err => {
            FemsToastrService.error(err?.message)
          })
      }
    },

    editRow(rowData) {
      this.getGroups()
      // this.getPaymentCycle()
      StdFeeConfigService.editStdFeeConfig(rowData.id).then(res => {
        if (!res.data.status) {
          return
        }
        const responseData = res.data.data
        console.log('🚀 ~ file: StdFeeConfig.vue ~ line 899 ~ StdFeeConfigService.editStdFeeConfig ~ responseData', responseData)
        this.isUpdate = true
        this.updateId = responseData.id
        this.stdFeeConfigData.feeName = responseData.coa_id
        this.stdFeeConfigData.amount = responseData.amount
        this.stdFeeConfigData.priority = responseData.priority
        this.stdFeeConfigData.affectOn = responseData.affect_on
        this.stdFeeConfigData.groupName = responseData.group_option_id
        this.stdFeeConfigData.applicableOn = responseData.applicable_on
        this.stdFeeConfigData.campusIds = responseData.campus_ids
        this.stdFeeConfigData.vatApplicable = responseData.has_vat
        this.stdFeeConfigData.typeId = responseData.type_id
        this.getPaymentCycle(this.stdFeeConfigData.campusIds)
        this.stdFeeConfigData.paymentCycleIds = responseData.payment_cycle_ids
      })
      this.$bvModal.show('std-fee-config-modal')
    },

    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          StdFeeConfigService.deleteStdFeeConfig(rowData.id).then(res => {
            if (res.data.status) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Data Deleted successfully',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              // Data load for grid view in pc config
              this.getGridData()
            } else {
              FemsToastrService.error(res?.data.message)
            }
          })
            .catch(err => {
              FemsToastrService.error(err?.message)
            })
        }
      })
    },
    resetForm() {
      this.stdFeeConfigData = {
        feeName: '',
        groupName: '',
        amount: '',
        priority: '',
        affectOn: 1,
        typeId: '',
        campusIds: [],
        paymentCycleIds: [],
        vatApplicable: 0,
        applicableOn: 1,
      }

      this.searchTerm = ''

      this.isUpdate = false
      this.updateId = ''

      this.feeNameState = null
      this.groupNameState = null
      this.amountState = null
      this.priorityState = null
      this.affectionState = null

      this.stdFeeConfigPaymentCycleOptions = []
    },

    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
